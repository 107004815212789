import React, { useState, useEffect, useRef } from 'react'
import { FaChevronDown } from 'react-icons/fa'

import './Navigation.scss'
import GatsbyLink from '../../../GatsbyLink'
import Button from '../../../Button'
import SubNavigation from '../SubNavigation/'

const Navigation = ({
  className,
  navItems,
  handleSubmenuClick,
  isSubMenuOpen,
  isMobileOpen,
}) => {
  return (
    navItems && (
      <nav
        className={`primary-navigation-container ${
          isMobileOpen ? 'active' : ''
        }`}
      >
        <ul className={className}>
          {navItems.map((item, index) => (
            <li key={index}>
              <GatsbyLink
                to={item?.url}
                target={item?.target ? item.target : null}
                aria-label={item?.title && item.title}
                decode={true}
              >
                {item?.title && item.title}
              </GatsbyLink>
              {item?.children && item.children[0].url !== '#gatsby' && (
                <>
                  <button
                    type="button"
                    className="expand"
                    onClick={() => handleSubmenuClick()}
                    aria-label="Open Menu"
                  >
                    <FaChevronDown />
                  </button>
                  <SubNavigation
                    childNav={item?.children}
                    isOpen={isSubMenuOpen}
                    parentTitle={item?.title}
                  />
                </>
              )}
            </li>
          ))}
        </ul>
      </nav>
    )
  )
}

export default Navigation

import React from 'react'

import './Hamburger.scss'

const Hamburger = ({handleClick, isActive}) => {
  return (
    <div className="hamburger-container">
      <button
        type="button"
        className={`hamburger ${isActive}`}
        onClick={handleClick}
        aria-label="Toggle Menu"
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </button>
    </div>
  )
}

export default Hamburger

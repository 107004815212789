import React, { useState } from 'react'

import './SubNavigation.scss'
import GatsbyLink from '../../../GatsbyLink'
import { Image } from '../../../Image'

const SubNavigation = ({ childNav, isOpen, parentTitle }) => {
  // on load sets feature image to the first item in menu builder
  const [featureImage, setFeatureImage] = useState(0)

  return (
    <div className={`sub-navigation ${isOpen ? 'animate' : ''}`}>
      <div className="header-container">
        <div className="wrap">
          <span className="sub-navigation--title">{parentTitle}</span>
          <ul className="navigation">
            {childNav.map((child, index) => {
              const { title, url, target } = child
              return (
                <li key={index} onMouseEnter={() => setFeatureImage(index)}>
                  <GatsbyLink
                    to={url}
                    target={target ? target : null}
                    aria-label={title}
                    decode={true}
                  >
                    {title}
                  </GatsbyLink>
                </li>
              )
            })}
          </ul>
          <div className="image-container">
            <Image
              className="image"
              src={childNav[featureImage]?.acf?.featureImage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubNavigation

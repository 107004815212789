import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import './Footer.scss'
import Button from '../Button'
import Carousel from '../Carousel'
import { Logo } from '../../../theme/Logo'
import GatsbyLink from '../GatsbyLink'
import { SocialLinks } from '../SocialLinks'
import abelRoofing from '../../images/logos/abel-roofing.png'
import stratco from '../../images/logos/stratco.png'
import bluescope from '../../images/logos/bluescope.png'
import hia from '../../images/logos/hia.png'

const Footer = ({
  location,
  data: {
    footerNavigation,
    siteSettings,
    footerBrochures: {
      acf: { footerBrochures },
    },
    wordpressWpSettings,
  },
}) => {
  const {
    phone,
    addressStreet,
    addressCity,
    addressRegion,
    addressPostCode,
    openingHours,
  } = siteSettings.options.locations[0]
  const currentYear = new Date().getFullYear()
  const carousel_settings = {
    container: `footer-carousel-inner`,
    loop: false,
    nav: false,
    mouseDrag: true,
    controls: true,
    gutter: 30,
    center: true,
    fixedWidth: 300,
    responsive: {
      1600: {
        items: 2.2,
      },
      1080: {
        items: 2,
        fixedWidth: 347,
      },
      360: {
        items: 1,
        fixedWidth: 347,
      },
    },
    speed: 400,
  }

  return (
    <>
      {!/stratco-patios|contact-us/.test(location.pathname) && (
        <div className="footer-quote">
          <div className="footer-container">
            <div className="wrap">
              <p className="footer-quote-title">
                Upgrade your shade with an obligation Free Quote
              </p>
              <div className="contact-options">
                <Button to="/contact-us/" bgColour="white" textColor="black">
                  Contact Us
                </Button>
                <span>
                  Or Call Us on{' '}
                  <a href={`tel:${phone}`} className="phoneNumber">
                    {phone}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {location.pathname == '/stratco-patios/' && (
        <div className="footer-quote">
          <div className="footer-container">
            <div className="wrap">
              <p className="footer-quote-title">
                Find out more about our Stratco offerings
              </p>
              <div className="contact-options">
                <Button
                  to="/contact-us/request-a-quote/"
                  bgColour="white"
                  textColor="black"
                >
                  Request a quote
                </Button>
                <span>
                  Or Call Us on{' '}
                  <a href={`tel:${phone}`} className="phoneNumber">
                    {phone}
                  </a>
                </span>
              </div>
            </div>
          </div>

          {footerBrochures && (
            <Carousel settings={carousel_settings}>
              {footerBrochures.map((brochure, slide_count) => {
                return (
                  <div className="slide" key={slide_count}>
                    <div className="slide-content">
                      <p className="emphasised--paragraph">
                        {brochure.brochure.title}
                      </p>
                      <Button
                        bgColour="primary"
                        textColor="white"
                        to={brochure.brochure.url}
                      >
                        View brochure
                      </Button>
                    </div>
                  </div>
                )
              })}
            </Carousel>
          )}
        </div>
      )}

      <footer>
        <div className="footer-container">
          <div className="wrap">
            <div className="logo-container">
              <Logo />
            </div>
            <div>
              <ul className="footer-navigation">
                {footerNavigation?.items &&
                  footerNavigation?.items.map((item, index) => (
                    <li key={index}>
                      <GatsbyLink
                        to={item.url}
                        aria-label={item.title}
                        decode={true}
                      >
                        {item.title}
                      </GatsbyLink>
                    </li>
                  ))}
              </ul>
              <div className="contact-details">
                <div>
                  <span className="contact-title">Address</span>
                  <address>
                    {addressStreet}
                    <br />
                    {addressCity} {addressRegion} {addressPostCode}
                  </address>
                </div>
                <div>
                  <span className="contact-title">Opening Hours</span>
                  <p>{openingHours}</p>
                </div>
              </div>
            </div>
            {siteSettings.options.socialLinksFooter && <SocialLinks />}
            <div className="partners">
              <GatsbyLink to="http://www.bluescopesteel.com.au/">
                <img src={bluescope} alt="Bluescope" />
              </GatsbyLink>
              <GatsbyLink to="https://hia.com.au/">
                <img src={hia} alt="HIA" />
              </GatsbyLink>
            </div>
            <div className="copyright">
              <span>
                ©{currentYear} {wordpressWpSettings.title}
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "legal-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        }
        footerNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "footer-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        }
        footerBrochures: wordpressPage {
          acf {
            footerBrochures {
              brochure {
                title
                url
              }
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressCity
              addressPostCode
              addressRegion
              addressStreet
              phone
              openingHours
            }
            footerTagline
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)

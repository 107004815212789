import React from 'react';
import Helmet from 'react-helmet';
import '../../sass/global/styles.scss';
import Header from '../Header';
import Footer from '../Footer';

const Layout = ({ children, location, wordpressUrl, heroTheme }) => (
  <div id="layout">
    <Header location={location} heroTheme={heroTheme} />
    <div className="main">{children}</div>
    <Footer location={location} />
  </div>
)

export default Layout
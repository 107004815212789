import React from 'react'
import PropTypes from 'prop-types'

import './Button.scss'
import GatsbyLink from '../GatsbyLink'

const Button = ({
  style = 'pill',
  bgColour = 'primary',
  textColor = 'white',
  borderColor,
  small,
  to,
  className = '',
  children,
}) => {
  const bgClass = `bg-${bgColour}`
  const borderClass = borderColor ? `border-${borderColor}` : ''
  const colourClass = textColor ? `color-${textColor}` : ''
  return (
    <GatsbyLink className={`button-container ${className}`} to={to}>
      <div
        className={`button ${style} ${bgClass} ${borderClass} ${colourClass} ${small &&
          `button--small`}`}
      >
        {children}
      </div>
    </GatsbyLink>
  )
}

Button.propTypes = {
  style: PropTypes.oneOf(['pill', 'circle']),
  bgColour: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  small: PropTypes.bool,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Button

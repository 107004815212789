import React, { useState, useEffect, useRef } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useSpring, animated } from 'react-spring'

import { Logo } from '../../../theme/Logo'
import PrimaryNavigation from './Navigation/PrimaryNavigation'
import Hamburger from './Navigation/Hamburger'
import GatsbyLink from '../GatsbyLink'
import Button from '../Button'
import './Header.scss'
import './theme.scss'

const Header = data => {
  const { primaryNavigation, wordpressWpSettings } = data.data
  const { location } = data
  const { baseUrl } = wordpressWpSettings
  const { items: navItems } = primaryNavigation

  const [isMobileOpen, setMobileOpen] = useState(false)
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)

  // logic for whether there should be the green border top. Homepage only
  const [isHomepage, setHomepage] = useState(null)
  useEffect(() => {
    if (location.pathname === '/') {
      setHomepage(true)
    }
  }, [])

  // logic for when to kick in the header background colour
  const [scrollTop, setScrollTop] = useState(false)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      setScrollTop(currPos.y < 0)
    },
    [scrollTop]
  )

  const currentStateLogic = returnStatement => {
    return scrollTop || isMobileOpen
      ? returnStatement
      : isSubMenuOpen
      ? returnStatement
      : null
  }

  // background animation and theme settings
  const theme = data.heroTheme.toLowerCase()
  const backgroundProps = useSpring({
    background: 'rgba(255, 255, 255, 1)',
    from: { background: 'rgba(255, 255, 255, 0)' },
  })

  // event handlers for when outside of the menu is clicked or esc is pushed
  const menuRef = useRef(null)
  const handleHideSubMenu = event => {
    if (event.key === 'Escape') {
      setSubMenuOpen(previousState => !previousState)
    }
  }

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setSubMenuOpen(previousState => !previousState)
    }
  }

  useEffect(() => {
    if (isSubMenuOpen) {
      document.addEventListener('keydown', handleHideSubMenu, true)
      document.addEventListener('click', handleClickOutside, true)
      return () => {
        document.removeEventListener('keydown', handleHideSubMenu, true)
        document.removeEventListener('click', handleClickOutside, true)
      }
    }
  })

  return (
    <animated.header
      style={currentStateLogic(backgroundProps)}
      className={`
        navigation--${theme}
        ${isHomepage ? 'navigation--home' : ''}
        ${currentStateLogic('animate')}
      `}
    >
      <div className="header-container" ref={menuRef}>
        <div className="wrap">
          <Logo
            colour={
              scrollTop || isSubMenuOpen || isMobileOpen || isHomepage
                ? 'black'
                : theme === 'white'
                ? 'black'
                : 'white'
            }
          />
          {/* // this can be better.. */}
          <Hamburger
            handleClick={() => setMobileOpen(previousState => !previousState)}
            isActive={isMobileOpen ? 'active' : ''}
          />

          <PrimaryNavigation
            className="primary-navigation"
            navItems={navItems}
            baseUrl={baseUrl}
            handleSubmenuClick={() =>
              setSubMenuOpen(previousState => !previousState)
            }
            isSubMenuOpen={isSubMenuOpen}
            isMobileOpen={isMobileOpen}
          />
          <a className="button-container phone-button" href="tel:0894171077">
            <div className={`button pill bg-white border-black color-black button--small`}><span className="t-hide">08 9417 1077</span></div>
          </a>
          <Button
            small
            className="contact-button"
            to="/contact-us/request-a-quote/"
          >
            Get A Free Quote
          </Button>
        </div>
      </div>
    </animated.header>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        primaryNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "primary-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            object_id
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
              acf {
                featureImage {
                  source_url
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 240, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
